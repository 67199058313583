@font-face {
  font-family: Libre;
  src: url("../../Assets/fonts/Libre/LibreCaslonText-Regular.ttf");
}
@font-face {
  font-family: Fibra;
  src: url("../../Assets/fonts/fibra/Fibra-Regular.otf");
}
.services-experience {
  background-color: #1e3957;
  color: white;
  margin-top: 60px;
}
.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
}
.servicesTitle {
  font-family: "Libre";
  font-size: 2.5rem;
  color: #C9A45C;
}

.imgSpace {
  justify-content: center;
  text-align: center;
  display: flex;
}
.SvgContainer {
  height: 150px;
  width: 300px;
}
.titleCard {
  font-size: 17px;
  margin-bottom: 15px;
  margin-top: 50px;
  font-family: "Libre";
  font-weight: bold;
  font-weight: 500;
  justify-content: center;
  color: #C9A45C;
}

.btnVerMas {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  background-color: unset;
}
.btnVerMas:hover {
  margin-left: 5px;
}
.cardContainer p {
  font-style: montserrat;
}

.serviceDesciption {
  font-family: "Fibra";
  text-align: center;
  width: 300px;
  margin-left: 10px;
  margin-right: 10px;
}

@media screen and (max-width: 300px) {
  .cardContainer {
    margin-top: 10px;
    width: 180px;
  }
  .imgSpace {
    width: 1px;
    margin-left: 95px;
  }
  .SvgContainer img {
    width: 250px;
    height: 200px;
  }
  .titleCard {
    margin-top: 150px;
  }
  .serviceDesciption {
    width: 250px;
    margin-left: -30px;
  }
}
