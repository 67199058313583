.marquee {
  margin-top: -80px;
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 25px;
    overflow-x: hidden;
    background-color: #1e3957;;
  }
  
  .track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 32s linear infinite;
  }
  .marquee .content {
    color: #C9A45C;
  }
  @keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
  }
  