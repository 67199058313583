@font-face {
  font-family: Libre;
  src: url("../../Assets/fonts/Libre/LibreCaslonText-Regular.ttf");
}
.AboutBody {
  display: flex;
  flex-direction: row;
  padding: 0px 3.5rem 0 0.5rem;
  min-height: 100%;
  height: fit-content;
  font-size: 1.5rem;
  padding-bottom: 7rem;
  position: relative;
  margin-top: 35px;
  /* margin-bottom: 30px; */
  background-color: #1e3957;
}

.AboutContainer {
  
  padding-top: 3em;
  padding-left: 3.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
}

.StyledHeading {
  display: flex;
  font-size: 2rem;
  text-align: center;
  font-weight: 1000;
  font-family: "Libre";
}
.Photo2 {
  /* margin-top: -0px; */
border: 2px solid #C9A45C;
border-radius: 10px;
}

.TextSection {
  font-size: 1.2rem;
  padding-top: 1.3em;
  line-height: 1.4em;
  color: black;
  text-align: justify;
}
.Photo {
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 400px;
  /* border-radius: 20px; */
  transition: 0.3s ease-in;
  align-self: center;
  margin-top: 8rem;

  /*  filter: grayscale(100%); */
}
.textPosition{
  margin-top: 100px;

  width:400px;
  margin-left: -50px;
}
.fotoo {
  margin-top: -15%;
  margin-left: -50px;
  /* border: solid red; */
}
.rh5v-DefaultPlayer_component {
  margin-left: 200px;
  height: 400px;
  width: 800px;
}
/* .rh5v-Overlay_component {
  border: 2px solid red;
  height: 50px;
  ;
} */

.btninfo {
  height: 50px;
  width: 170px;
  background-color: black;
  /* border-radius: 20px; */
  color: white;
}
.position {
  text-align: center;
  padding: 1rem;
  /* border: 2px solid red; */
  display: flex;
  align-items: flex-end;
}

.btnFlotante {
  font-size: 15px;
  border-radius: 200px;
  position: fixed;
  bottom: 40px;
  padding: 10px 10px;
  left: 40px;
  transition: all 300ms ease 0ms;
  z-index: 99;
  width: 80px;
  height: 80px;
}
.btnFlotante:hover {
  background-color: #eeeeee;
  transform: translateY(-7px);
}

@media screen and (max-width: 1160px) {
  .rh5v-DefaultPlayer_component {
    margin-left: 100px;
   
  }
}
@media screen and (max-width: 1040px) {
  .TextSection {
    font-size: 0.8rem;
    padding-top: 0.6em;
    line-height: 0.7em;
    color: black;
    text-align: justify;
  }
  .Photo2 {
    width: 400px;
    margin-top: 0%;
  }
  .Photo {
    width: 300px;
    margin-bottom: 2%;
  }
  .rh5v-DefaultPlayer_component {
    margin-left: 70px;
   
  }
}
@media screen and (max-width: 984px) {
  .AboutBody {
    flex-direction: column;

  }
  
  .Photo2{
    display: none;
  }
  .rh5v-DefaultPlayer_component {
    margin-left: 100px;
    height: 600px;
    width: 600px;
  }
}

@media screen and (max-width: 779px) {
  .AboutBody {
    display: flex;
    flex-direction: row;
    padding: 0px 3.5rem 0 0.5rem;
    min-height: 100%;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 1.5rem;
    padding-bottom: 7rem;
    position: relative;
    flex-wrap: wrap;
    align-content: space-around;
    margin-top: 70px;
  }
  .AboutContainer {
    padding-top: 1em;
    width: 100%;
  }

  .Photo {
    filter: none;
    margin-top: 0;
  }
  .Photo:hover {
    filter: none;
    transform: none;
  }
  .btnFlotante {
    font-size: 13px;
    padding: 12px 20px;
    bottom: 20px;
    left: 20px;
  }
  .rh5v-DefaultPlayer_component {
    margin-left: 5px;
    margin-right: 50px;
  }
}
@media screen and (max-width: 700px) {
  .rh5v-DefaultPlayer_component {
    margin-left: 50px;
    height: 400px;
      width: 400px;
  }
}

@media screen and (max-width: 600px) {
.rh5v-DefaultPlayer_component {
  margin-left: 10px;
  height: 400px;
    width: 400px;
}
}
@media screen and (max-width: 482px) {
  .Photo2 {
    width: 300px;
    margin-left: 35px;
  }
  .Photo {
    width: 350px;
    margin-left: -20px;
  }
  .rh5v-DefaultPlayer_component {
    margin-left: 1px;
    height: 500px;
    width: 300px;
  }
}

@media screen and (max-width: 445px) {
  .Photo {
    margin-top: 0;
    width: 300px;
    height: 300px;
  }
  .Photo2 {
    margin-top: 10px;
    margin-left: -30px;
    width: 300px;
  }
  .btninfo {
    margin-left: -70px;
  }
  .StyledHeading {
    display: flex;
    font-size: 2rem;
    text-align: left;
    font-weight: 1000;
    font-family: "lovelo";
    /* border: solid red; */
    margin-left: -35px;
  }
  /* .fotoo{ */
  /* margin-left: -10px; */
  /* border: solid salmon; */
  /* } */
  .rh5v-DefaultPlayer_component {
    margin-left: -10px;
  }
}

@media screen and (max-width: 375px) {
  .Photo {
    margin-top: 0;
    width: 250px;
    height: 250px;
  }
  .Photo2 {
    margin-top: 10px;

    margin-left: -20px;
    width: 250px;
  }
  .btninfo {
    margin-left: -50px;
  }
  .StyledHeading {
    font-size: 1.5rem;
    margin-left: -45px;
  }
  .fotoo {
    width: 250px;
  }
  .rh5v-DefaultPlayer_component {
    margin-left: -10px;
    height: 500px;
    width: 200px;
  }
}
@media screen and (max-width: 290px) {
  .Photo {
    margin-top: 125px;
    width: 250px;
    height: 250px;
  }
  .Photo2 {
    margin-top: 5px;
    margin-left: -20px;
    width: 200px;
  }
  .btninfo {
    margin-left: -50px;
  }
  .StyledHeading {
    font-size: 0.9rem;
    margin-left: -45px;
    /* border: solid red; */
    margin-bottom: -15px;
  }
  .fotoo {
    /* border: solid red; */
    width: 20px;
  }
  .rh5v-DefaultPlayer_component {
    margin-left: -20px;
   
  }
}
