@import url("https://fonts.googleapis.com/css2?family=Exo+2&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Labrada&display=swap");
@font-face {
  font-family: Libre;
  src: url("../../Assets/fonts/Libre/LibreCaslonText-Regular.ttf");
}

.navbar {
  width: 100%;
  height: 75px;
  /* background-color: #1650BD; */
  background-color: #1e3957;
  color: rgb(255, 255, 255);

  transition: all 0.3s ease-out 0s !important;
  padding: 0.5rem 3rem !important;
  font-size: 1.3rem !important;
}
.sticky {
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgb(239, 238, 245) !important;
  backdrop-filter: blur(15px) !important;
}
.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #f8f0f0;
  height: 4px !important;
  width: 28px !important;
  margin-top: 3px !important;
  margin-bottom: 5px !important;
  margin-right: 2rem !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}
.logo {
  background-image: url(../../Assets/acpLogo.png);
  background-size: cover;
  margin-top: -25px;
  width:120px;
  height: 90px;
}

.navbar-nav .nav-link {
  font-family:Libre ;
  color: white !important;
  font-size: 16px !important;
  letter-spacing: 1px;
  font-weight: 500 !important;
  padding-right: 1rem !important;
  padding-left: 1.5rem !important;
}
.navbar-nav .nav-link:hover {
  color: #c9a45c !important;
}
.resumebtn {
  margin-left: 30px;
  border-radius: 25px;
  padding: 0 25px;
  background-color: #282c34;
  background-image: linear-gradient(43deg, #7d90f0 0%, #282c34 100%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 10px;
  border: none;
}

@media (max-width: 770px) {
  .navbar {
    padding: 0rem 0.1rem 0.1rem 0rem !important;
    background-color: #282c34 !important;
    color: rgb(255, 255, 255);
    /* border: solid red; */
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
  .responsive-navbar {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    background-color: #282c34;
    background-image: #282c34;
  }
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .resumebtn {
    width: 150px;
    display: block;
    margin: 5px auto;
    padding: 8px 5px;
    letter-spacing: 1px;
  }
  .logo {
    width: 75px;
    height: 75px;
  }
}
/* @media (max-width:350) {
    .logo{

    }
    
  } */
