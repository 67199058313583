
@font-face {
  font-family: Libre;
  src: url("../../Assets/fonts/Libre/LibreCaslonText-Regular.ttf");
}
@font-face {
  font-family: Fibra;
  src: url("../../Assets/fonts/fibra/Fibra-Regular.otf");
}

.aboutSection{
  background-color: #1e3957;
  color: rgb(255, 255, 255);
  
  /* border: 2px solid red; */
}
.textAboutPart{
    text-align: center;
    margin-left: 10%;
    margin-right: 10%; 
    font-family: "Fibra";
  }
  .Listado{
    /* border: 2px solid red; */
    text-align: center;
    /* -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;  */
    
  }
  .mision{
    margin-top: 25px;
    /* border: solid red;     */
  }
  .vision{
    margin-top: 25px;
    /* border: solid red;  */
  }
  .titleAbout{
    font-family: "Libre";
    font-size: 2.5rem;
    color: #C9A45C;
  }
  .UlForm{
    
    margin-left: -50px;
    
  }
  /* .UlForm >div{ */
    /* width: 50%; */
    /* } */ 
    /* 
    
    li{
      line-height:1.5em;
      border-bottom:1px solid #b90f0f;
      float:left;
      display:inline;
    } */
    ul{
      text-align: left;
      margin:0%;
      text-decoration-color: #ccc;
      padding: 0;
      /* width: 250px; */
      /* margin-left: 0%; */
      /* border: solid rgb(0, 128, 255); */
      /* margin-bottom:20px; */
      /* overflow:hidden;s */
      /* border-top:1px solid #ccc; */
    }
    .SpaceBody2{
      /* border: solid red;  */
      display: flex;
      width: 98%;
      margin-top: 25px;
      /* margin-bottom: 3rem; */
      justify-content: flex-start;
      /* border: solid green; */
      flex-wrap: nowrap;
      flex-direction: column;
      align-content: stretch;
      align-items: center;
      
    }
    
    .StyledHeading2 {
      display: flex;
      font-size: 1.5rem;
      justify-content: center;
      color: yellow;
      /* border: solid rgb(186, 207, 65);s */
      margin-left: 10%;
      margin-right: 10%;
    }
    .textContainer2 { 
      font-family: "Fibra";
      Font-size:15px;
      height:120px;
      /* margin-top: 10%; */
      /* border:solid #b90f0f; */
      text-align: justify;
      font-size: 1.2rem;
      margin-left: 10px;
      /* margin-right: 2%; */
    }
    .aboutSection h3{
      font-family: "Libre";
      color: #C9A45C;
    }
    .TextSection2{
      font-family: "Fibra";
      /* margin-left: 5%; */
      text-decoration-color: #c0a7a7;
      /* Font-size:15px;s */
      /* height:150px; */
      margin-top: 0%;
      text-align: justify;
      font-size: 1.2rem;
      margin-right: 10px;
      /* text-align: left; */
      /* font-size: 1.2rem; */
      /* width:150px; */
      /* border:solid #c8c2c2; */
      /* margin-top: 50%; */
    }
    .block2{
      /* border: solid rgb(7, 174, 230); */
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;
      align-items: stretch;
      margin-left: 5%;
      /* 
      margin-right: -5%; */
    }
    @media screen and (max-width: 320px) {
      .block2{
        flex-direction: column;
        align-items: center;
        width: 292px;
    }
    .TextSection2{
        margin-left: -65px;
    }

  
  }
  @media screen and (max-width: 310px) {
    .block2{
        flex-direction: column;
        align-items: center;
        width: 200px;
    }
    .TextSection2{
        margin-left: -20px;
    }

  
  }