@font-face {
  font-family: Libre;
  src: url("../../Assets/fonts/Libre/LibreCaslonText-Regular.ttf");
}
@font-face {
  font-family: Fibra;
  src: url("../../Assets/fonts/fibra/Fibra-Regular.otf");
}

.Testimonials-experience {
    background-color: #1e3957;
    color: white;
    margin-top: 40px;
  }
  .containerTest {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 20px;
    /* border: solid red; */
  }
  .TestimonialsTitle{
    font-family: "Libre";
      font-size: 2.5rem;
      color: #C9A45C;
  }
  
 
  .SvgContainerTest {
    height: 50px;
    width: 100px;
    margin-bottom: 2px;
    /* border: solid red; */

    /* transform: scaleY(-1) scaleX(-1); */
  }
  .titleCardTest {
   
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 1px;
    margin-top: 1px;
  }
  .cardContainerTest{
    /* border: solid red; */
    font-family: "Libre";
    display: flex;
    flex-direction: column;
 
    align-items: center;
    margin-bottom: 20px;
  }
  .cardContainerTest p {
    font-style: italic;
    margin-top: 2px;
  }
  
  .TestimonialDesciption{
    font-family: "Fibra";
      text-align: center;
      width: 300px;
      margin-left: 10px;
      margin-right: 10px;
  }
  @media screen and (max-width: 310px) {
.TestimonialDesciption{
  width: 200px;
  text-align: center;
  align-items: center;
  margin-left: 50px;
}
.titleCardTest{
  font-size: 15px;
  text-align: center;
  margin-left: 30px;
}
.TestimonialsTitle{
  font-size: 2.5rem;
}

  .titleCardTest{
    width: 250px;
  }
  .containerTest{
    width: 260px;
  }

  }
  