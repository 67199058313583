@font-face {
  font-family: Libre;
  src: url("../../Assets/fonts/Libre/LibreCaslonText-Regular.ttf");
}

.AboutContact {
  display: flex;
  flex-direction: column;
  width: 98%;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 3rem;
  justify-content: center;
  align-items: center;
  /* justify-content: space-around; */
 /* border: solid green; */
}

.photoContact {
  height: 300px;
  width: 400px;
  /* border-radius: 15px; */
  background-image: url("../../Assets/contact.avif");
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
/* .photoContact:hover {
  filter: grayscale(80%);
  transform: scale(1.1);
  transition: 500ms;
} */

.StyledHeadingContact {
  color: #C9A45C;
  display: flex;
  font-size: 2.5rem;
font-family: "Libre";
  /* border: solid red; */
  margin-bottom: 1px;
  margin-top: 35px;
}

.TextSectionContact {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 1px;
  text-align: justify;
  font-size: 1.2rem;
  width: 500px;
  color: white;
  display: flex;
  flex-direction: column-reverse;
  /* border: solid red; */
}

@media screen and (max-width: 1200px) {
  .AboutContact {
    flex-direction: column;
    align-items: center;
  }
  .TextSectionContact,
  .StyledHeadingContact {
    width: 90%;
    margin: 0 auto;
  }
  
  .photoContact {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .TextSectionContact {
    /* border: solid red; */
    margin-top: 70px;
  }
}

@media screen and (max-width: 620px) {
  .photoContact {
    width: 320px;
    height: 320px;
  }
  .TextSectionContact {
    margin-top: 40px;
    /* border: solid red; */
    width: 300px;
    height: 200px;

  }
}
@media screen and (max-width: 315px) {
  .photoContact {
    width: 200px;
    height: 200px;
  }

}
